module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://worldsunlocked.riotgames.com/","site_name":"2023 Worlds Unlocked: The Grind, The Glory Collector's Edition Box","description":"Experience Worlds like never before. This first-of-its-kind, exclusive, limited edition box set is designed to level up your LoL Esports fandom."},"twitter":{"cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-us","en-gb","en-au","en-sg","en-ph","cs-cz","de-de","el-gr","es-es","es-mx","fr-fr","hu-hu","id-id","it-it","ms-my","pl-pl","pt-br","ro-ro","ru-ru","th-th","tr-tr","vi-vn","ko-kr","ja-jp","zh-tw"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"ns":["translations"],"defaultNS":"translations","load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?/example-one","getLanguageFromPath":true,"excludeLanguages":["en-us","en-gb","en-au","en-sg","en-ph","cs-cz","de-de","el-gr","es-es","es-mx","fr-fr","hu-hu","id-id","it-it","ms-my","pl-pl","pt-br","ro-ro","ru-ru","th-th","tr-tr","vi-vn","ko-kr","ja-jp","zh-tw"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["en-us","en-gb","en-au","en-sg","en-ph","cs-cz","de-de","el-gr","es-es","es-mx","fr-fr","hu-hu","id-id","it-it","ms-my","pl-pl","pt-br","ro-ro","ru-ru","th-th","tr-tr","vi-vn","ko-kr","ja-jp","zh-tw"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBTKWX6V","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"08e0ad135c684ad4142504bd31b3e610"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
